import React from 'react';
import type { Organism } from '@verndale/core';
import globModules from './glob-modules';
import { renderElement } from './helpers/react-18-shim';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reactModule = (Component: any, nodeList: NodeListOf<HTMLElement> | HTMLElement, data?: object) => {
  const nodesArray = Array.isArray(nodeList) ? nodeList : Array.from(nodeList as NodeListOf<HTMLElement>);

  return nodesArray.forEach(node => {
    renderElement(<Component {...node.dataset} data={data} />, node);
  });
};

const modules: Organism[] = [
  {
    name: 'sliderContainer',
    loader: () => import('./modules/sliderContainer')
  },
  {
    name: 'accordionList',
    loader: () => import('./modules/accordionList')
  },
  {
    name: 'accordion',
    loader: () => import('./modules/accordion')
  },
  {
    name: 'searchResults',
    loader: () => import('./modules/react/searchResults'),
    render: reactModule
  },
  {
    name: 'cardCarousel',
    loader: () => import('./modules/cardCarousel')
  },
  {
    name: 'contentVideo',
    loader: () => import('./modules/content-video')
  },
  {
    name: 'dynamicModal',
    loader: () => import('./modules/dynamicModal')
  },
  {
    name: 'popupForm',
    loader: () => import('./modules/popupForm')
  },
  {
    name: 'mockApi',
    loader: () => import('./modules/mockApi'),
    render: reactModule
  },
  {
    name: 'desktopHeader',
    loader: () => import('./modules/desktopHeader')
  },
  {
    name: 'mobileHeader',
    loader: () => import('./modules/mobileHeader')
  },
  {
    name: 'cart',
    loader: () => import('./modules/cart')
  },
  {
    name: 'productSample',
    loader: () => import('./modules/productSample')
  },
  {
    name: 'productSampleSelection',
    loader: () => import('./modules/productSampleSelection')
  },
  {
    name: 'productGallery',
    loader: () => import('./modules/productGallery')
  },
  {
    name: 'productGuideOverlay',
    loader: () => import('./modules/react/productGuideOverlay'),
    render: reactModule
  },
  {
    name: 'campaignSlider',
    loader: () => import('./modules/campaignSlider')
  },
  {
    name: 'campaignVideoCarousel',
    loader: () => import('./modules/campaignVideoCarousel')
  },
  {
    name: 'campaignContentCarousel',
    loader: () => import('./modules/campaignContentCarousel')
  },
  {
    name: 'tabs',
    loader: () => import('./modules/tabs')
  },
  {
    name: 'cartSample',
    loader: () => import('./modules/cartSample')
  },
  {
    name: 'ceraplusSteps',
    loader: () => import('./modules/ceraplusSteps')
  },
  {
    name: 'singleSelectQuestion',
    loader: () => import('./modules/singleSelectQuestion')
  },
  {
    name: 'singleLineQuestion',
    loader: () => import('./modules/singleLineQuestion')
  },
  {
    name: 'accessoriesCard',
    loader: () => import('./modules/accessoriesCard')
  },
  {
    name: 'geolocationModal',
    loader: () => import('./modules/geolocationModal')
  },
  {
    name: 'articleListing',
    loader: () => import('./modules/articleListing')
  },
  {
    name: 'articleRecommend',
    loader: () => import('./modules/articleRecommend')
  },
  {
    name: 'modal',
    loader: () => import('./modules/modal')
  },
  {
    name: 'checkout',
    loader: () => import('./modules/checkout')
  },
  {
    name: 'productCatalog',
    loader: () => import('./modules/productCatalog')
  },
  {
    name: 'articleListingSlider',
    loader: () => import('./modules/articleListingSlider')
  },
  {
    name: 'testimonialCarousel',
    loader: () => import('./modules/testimonialCarousel')
  },
  {
    name: 'footer',
    loader: () => import('./modules/footer')
  },
  {
    name: 'footer',
    loader: () => import('./modules/footer')
  },
];

export default [...globModules, ...modules];
