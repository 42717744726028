import { gsap, CSSPlugin } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollToPlugin);

/**
 * Opens an element
 *
 * @example
 * import {open} from '../helpers';
 *
 * open({ element: this.el.querySelector('.element)});
 *
 * @param {Object} element - HTML element you want to target for animation
 * @param {Number} duration - Speed of the animation
 * @param {String} ease - Ease of the animation
 * @param {Function} onComplete - On complete function
 */

type openProps = {
  element: HTMLElement;
  duration?: number;
  ease?: string;
  onComplete?: () => void;
  height?: string;
};

export const open = ({
  element,
  duration = 0.4,
  ease = 'easeOut',
  onComplete,
  height = 'auto'
}: openProps) => {
  gsap.set(element, { height });
  gsap.from(element, {
    height: 0,
    ease: ease,
    duration,
    onComplete: () => {
      onComplete && onComplete();
    }
  });
};

/**
 * Closes an element
 *
 * @example
 * import {close} from '../helpers';
 *
 * close({ element: this.el.querySelector('.element)});
 *
 * @param {Object} element - HTML element you want to target for animation
 * @param {Number} duration - Speed of the animation
 * @param {String} ease - Ease of the animation
 * @param {Function} onComplete - On complete function
 */

type closeProps = {
  element: HTMLElement;
  duration?: number;
  ease?: string;
  onComplete?: () => void;
};

export const close = ({ element, duration = 0.4, ease = 'easeOut', onComplete }: closeProps) => {
  gsap.set(element, { height: element.offsetHeight });
  gsap.to(element, {
    height: 0,
    ease: ease,
    duration,
    onComplete: () => {
      onComplete && onComplete();
    }
  });
};

// /**
// * Checks browser is running on a native mobile device
// */

type ExtendedWindowType = typeof window & {
  opera: string;
  MSStream: boolean;
};
export const isDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as ExtendedWindowType).opera;

  if (
    /windows phone/i.test(userAgent) ||
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !(window as ExtendedWindowType).MSStream)
  ) {
    return true;
  }

  return false;
};

// eslint-disable-next-line
export const setTrackDataLayer: (data: { eventName: string, custom: any }) => void = ({ eventName, custom = {} }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...custom
  });
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  } else {
    return false;
  }
};


export const recommendArticlePost = (endpoint: string, data: string) => {
  fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    body: data
  }).then(response => response.json())
    .then(data => {
      console.log(data);
    }).catch(err => {
      console.error(err);
    });
}


export const addInnerContainer = () => {
  const innerContainer = document.querySelector('.main-content:has(.sidebar)');

  if (innerContainer) {
    innerContainer.classList.add('inner_container');
  }
};